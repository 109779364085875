import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SpellsAndAbilities.css';

const SpellsAndAbilities = () => {
  const navigate = useNavigate();
  
  // States for spells and abilities
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingSpells, setIsLoadingSpells] = useState(false);
  const [spellLibrary, setSpellLibrary] = useState([]);
  const [allSpellsData, setAllSpellsData] = useState([]);
  const [filteredSpells, setFilteredSpells] = useState([]);
  const [expandedSpells, setExpandedSpells] = useState({});
  const [spellSourceBooks, setSpellSourceBooks] = useState([]);
  const [activeSpellSourceFilters, setActiveSpellSourceFilters] = useState([]);
  const [favoriteSpells, setFavoriteSpells] = useState([]);
  const [spellSearch, setSpellSearch] = useState('');
  const [activeSpellFilters, setActiveSpellFilters] = useState({
    level: [],
    school: [],
    prepared: false
  });
  const [filterMenuOpen, setFilterMenuOpen] = useState(null);
  const [viewMode, setViewMode] = useState('library');
  
  // Ability states
  const [abilityResources, setAbilityResources] = useState([]);
  const [activeClassFilter, setActiveClassFilter] = useState('All');
  
  // Spell slot states
  const [spellSlots, setSpellSlots] = useState({});

  // Token handling
  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  // Fetch character sheet data
  const fetchCharacterSheet = async () => {
    try {
      const token = getAuthToken();
      
      if (!token) {
        navigate('/login');
        return;
      }
      
      const response = await axios.get('/api/character/sheet', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      if (response.data && response.data.success) {
        setCharacterSheet(response.data.characterSheet);
      } else {
        console.error('Failed to fetch character sheet');
      }
    } catch (error) {
      console.error('Error fetching character sheet:', error);
    }
  };

  // Initialize spell data from JSON files and character sheet
  useEffect(() => {
    const initializeSpellData = async () => {
      try {
        console.log("Loading all spells from JSON files...");
        setIsLoadingSpells(true);
        
        // Load all spells from JSON files
        let allSpells = [];
        let sourceBooks = new Set();
        
        // Directly use the specific spell file names
        const spellFiles = [
          'spells-phb.json',
          'spells-tce.json',
          'spells-xge.json',
          'spells-aag.json',
          'spells-ai.json',
          'spells-aitfr-avt.json',
          'spells-bmt.json',
          'spells-dodk.json',
          'spells-egw.json',
          'spells-ftd.json',
          'spells-ggr.json',
          'spells-ghloe.json',
          'spells-idrotf.json',
          'spells-llk.json',
          'spells-sato.json',
          'spells-scc.json',
          'spells-tdcsr.json',
          'spells-xphb.json'
        ];
        
        // Load each spell file
        for (const filename of spellFiles) {
          try {
            console.log(`Loading ${filename}...`);
            const module = await import(`../data/spells/${filename}`);
            const source = filename.replace('spells-', '').replace('.json', '').toUpperCase();
            sourceBooks.add(source);
            
            if (module && module.spell) {
              if (Array.isArray(module.spell)) {
                // Handle arrays of spells
                const spellsWithSource = module.spell.map(spell => ({
                  ...spell,
                  source: source,
                  sourceBook: source
                }));
                allSpells = [...allSpells, ...spellsWithSource];
              } else {
                // Handle single spell objects
                allSpells.push({
                  ...module.spell,
                  source: source,
                  sourceBook: source
                });
              }
            }
          } catch (error) {
            console.error(`Error loading ${filename}:`, error);
          }
        }
        
        console.log(`Loaded ${allSpells.length} spells from ${sourceBooks.size} source books`);
        
        // Extract character abilities
        const abilities = characterSheet?.items
          ? characterSheet.items
              .filter(item => item.type === 'feat' || item.type === 'feature' || item.type === 'class')
              .map(ability => ({
                id: ability.id || ability._id,
                name: ability.name,
                class: ability.system?.class || getClassFromName(ability.name) || 'General',
                description: ability.system?.description?.value || 'No description available',
                uses: {
                  value: ability.system?.uses?.value || 0,
                  max: ability.system?.uses?.max || 0,
                  per: ability.system?.uses?.per || 'long rest'
                },
                active: false
              }))
          : [];
        
        // Initialize spell slots from character sheet
        const slots = {};
        if (characterSheet?.system?.spells) {
          for (let level = 1; level <= 9; level++) {
            const slotKey = `spell${level}`;
            if (characterSheet.system.spells[slotKey]) {
              slots[level] = {
                max: characterSheet.system.spells[slotKey].max || 0,
                used: characterSheet.system.spells[slotKey].max - (characterSheet.system.spells[slotKey].value || 0)
              };
            }
          }
        }
        
        // Update all the necessary state
        setSpellSourceBooks([...sourceBooks]);
        setActiveSpellSourceFilters([...sourceBooks]); // By default enable all sources
        setAllSpellsData(allSpells);
        setSpellLibrary(allSpells);
        setFilteredSpells(allSpells);
        setAbilityResources(abilities);
        setSpellSlots(slots);
        
        // Load favorites from localStorage
        const savedFavorites = localStorage.getItem('favoriteSpells');
        if (savedFavorites) {
          setFavoriteSpells(JSON.parse(savedFavorites));
        }
      } catch (error) {
        console.error("Error initializing spell data:", error);
      } finally {
        setIsLoadingSpells(false);
      }
    };
    
    fetchCharacterSheet().then(() => {
      initializeSpellData();
    });
  }, []);

  // Helper function to guess class from ability name
  const getClassFromName = (abilityName) => {
    const classKeywords = {
      'Rage': 'Barbarian',
      'Bardic': 'Bard',
      'Channel Divinity': 'Cleric',
      'Wild Shape': 'Druid',
      'Action Surge': 'Fighter',
      'Ki': 'Monk',
      'Divine Sense': 'Paladin',
      'Ranger': 'Ranger',
      'Sneak Attack': 'Rogue',
      'Sorcery': 'Sorcerer',
      'Warlock': 'Warlock',
      'Wizard': 'Wizard'
    };
    
    for (const [keyword, className] of Object.entries(classKeywords)) {
      if (abilityName.includes(keyword)) {
        return className;
      }
    }
    
    return 'General';
  };

  // Toggle spell expansion
  const toggleSpellExpand = (spellId) => {
    setExpandedSpells(prev => ({
      ...prev,
      [spellId]: !prev[spellId]
    }));
  };

  // Check if a spell is in favorites
  const isSpellFavorite = (spell) => {
    const spellId = getSpellId(spell);
    return favoriteSpells.some(fav => getSpellId(fav) === spellId);
  };

  // Add or remove a spell from favorites
  const handleAddToFavorites = (spell) => {
    const spellId = getSpellId(spell);
    
    if (isSpellFavorite(spell)) {
      // Remove from favorites
      const newFavorites = favoriteSpells.filter(fav => getSpellId(fav) !== spellId);
      setFavoriteSpells(newFavorites);
      localStorage.setItem('favoriteSpells', JSON.stringify(newFavorites));
    } else {
      // Add to favorites
      const newFavorites = [...favoriteSpells, spell];
      setFavoriteSpells(newFavorites);
      localStorage.setItem('favoriteSpells', JSON.stringify(newFavorites));
    }
  };

  // Generate a unique ID for each spell
  const getSpellId = (spell) => {
    return spell.id || `${spell.name}-${spell.source || spell.sourceBook || 'unknown'}`.replace(/\s+/g, '-').toLowerCase();
  };

  // Format casting time display
  const formatCastingTime = (spell) => {
    if (spell.time && spell.time.length > 0) {
      return `${spell.time[0]?.number || 1} ${spell.time[0]?.unit || 'action'}`;
    }
    return spell.system?.activation?.type || 'Action';
  };

  // Format range display
  const formatRange = (spell) => {
    if (spell.range) {
      return `${spell.range.distance?.amount || ''} ${spell.range.distance?.type || ''}`;
    }
    return spell.system?.range?.value || 'Self';
  };

  // Render the spell library
  const renderSpellLibrary = () => {
    // Check if we have any source books to filter by
    const hasSourceBooks = spellSourceBooks.length > 0;
    
    // Apply all filters to the spell list
    const displaySpells = (() => {
      // First filter by source
      let filtered = filteredSpells.filter(spell => {
        const spellSource = spell.source || spell.sourceBook || 'Unknown';
        return activeSpellSourceFilters.includes(spellSource) || activeSpellSourceFilters.length === 0;
      });
      
      // Then filter by search term
      if (spellSearch) {
        const searchLower = spellSearch.toLowerCase();
        filtered = filtered.filter(spell => 
          spell.name.toLowerCase().includes(searchLower)
        );
      }
      
      // Filter by level if any level filters are active
      if (activeSpellFilters.level.length > 0) {
        filtered = filtered.filter(spell => {
          const level = parseInt(spell.level || spell.system?.level || 0);
          return activeSpellFilters.level.includes(level);
        });
      }
      
      // Filter by school if any school filters are active
      if (activeSpellFilters.school.length > 0) {
        filtered = filtered.filter(spell => {
          const school = spell.school || spell.system?.school || 'Unknown';
          return activeSpellFilters.school.includes(school);
        });
      }
      
      // Filter by prepared status if the prepared filter is active
      if (activeSpellFilters.prepared) {
        filtered = filtered.filter(spell => spell.prepared || spell.system?.preparation?.prepared);
      }
      
      return filtered;
    })();
    
    // Sort spells by level, then name
    const sortedSpells = [...displaySpells].sort((a, b) => {
      const levelA = parseInt(a.level || a.system?.level || 0);
      const levelB = parseInt(b.level || b.system?.level || 0);
      if (levelA !== levelB) return levelA - levelB;
      return a.name.localeCompare(b.name);
    });
    
    return (
      <div className="spell-library-container">
        {isLoadingSpells ? (
          <div className="loading-spells">Loading spells library...</div>
        ) : (
          <>
            {/* Add source book filtering section */}
            {hasSourceBooks && (
              <div className="source-filters">
                <div className="filter-header">
                  <h4>Source Books</h4>
                  <div className="filter-actions">
                    <button 
                      className="select-all-btn"
                      onClick={() => setActiveSpellSourceFilters(spellSourceBooks)}
                    >
                      Select All
                    </button>
                    <button 
                      className="clear-all-btn"
                      onClick={() => setActiveSpellSourceFilters([])}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
                <div className="source-filter-list">
                  {spellSourceBooks.map(source => (
                    <button 
                      key={source}
                      className={`source-filter-btn ${activeSpellSourceFilters.includes(source) ? 'active' : ''}`}
                      onClick={() => {
                        setActiveSpellSourceFilters(prev => 
                          prev.includes(source) 
                            ? prev.filter(s => s !== source) 
                            : [...prev, source]
                        );
                      }}
                    >
                      {source}
                    </button>
                  ))}
                </div>
              </div>
            )}
            
            {/* Spell list table */}
            <div className="spell-list-container">
              <table className="spell-list-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Level</th>
                    <th>School</th>
                    <th>Casting Time</th>
                    <th>Range</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedSpells.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="no-spells-message">
                        No spells match your filters
                      </td>
                    </tr>
                  ) : (
                    sortedSpells.map(spell => {
                      const spellId = getSpellId(spell);
                      const isExpanded = expandedSpells[spellId];
                      const isFavorite = isSpellFavorite(spell);
                      
                      return (
                        <React.Fragment key={spellId}>
                          <tr className="spell-row">
                            <td className="spell-name">
                              <div className="spell-name-container">
                                <button 
                                  className="expand-btn"
                                  onClick={() => toggleSpellExpand(spellId)}
                                >
                                  {isExpanded ? '▼' : '►'}
                                </button>
                                <span>{spell.name}</span>
                              </div>
                            </td>
                            <td className="spell-level">
                              {spell.level === 0 ? 'Cantrip' : `Level ${spell.level}`}
                            </td>
                            <td className="spell-school">{spell.school}</td>
                            <td className="spell-casting-time">{formatCastingTime(spell)}</td>
                            <td className="spell-range">{formatRange(spell)}</td>
                            <td className="spell-actions">
                              <button 
                                className={`favorite-btn ${isFavorite ? 'favorite' : ''}`}
                                onClick={() => handleAddToFavorites(spell)}
                                title={isFavorite ? "Remove from favorites" : "Add to favorites"}
                              >
                                {isFavorite ? '★' : '☆'}
                              </button>
                            </td>
                          </tr>
                          {isExpanded && (
                            <tr className="spell-details-row">
                              <td colSpan="6" className="spell-details">
                                <div className="spell-description">
                                  <div className="spell-meta">
                                    <div className="spell-meta-item">
                                      <strong>Components:</strong> {spell.components?.v ? 'V' : ''}{spell.components?.s ? 'S' : ''}{spell.components?.m ? 'M' : ''}
                                      {spell.components?.m ? ` (${spell.components.m})` : ''}
                                    </div>
                                    <div className="spell-meta-item">
                                      <strong>Duration:</strong> {spell.duration || 'Instantaneous'}
                                    </div>
                                    <div className="spell-meta-item">
                                      <strong>Source:</strong> {spell.source || spell.sourceBook || 'Unknown'}
                                    </div>
                                  </div>
                                  <div 
                                    className="spell-text"
                                    dangerouslySetInnerHTML={{ __html: spell.entries ? spell.entries.join('<br>') : spell.system?.description?.value || 'No description available' }}
                                  />
                                  {spell.entriesHigherLevel && (
                                    <div className="higher-levels">
                                      <strong>At Higher Levels:</strong>
                                      <div dangerouslySetInnerHTML={{ __html: spell.entriesHigherLevel[0].entries.join('<br>') }} />
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    );
  };

  // Function to render the spell slot tracker
  const renderSpellSlotTracker = () => {
    const handleSlotChange = (level, index, used) => {
      // Clone the current spell slots state
      const newSlots = {...spellSlots};
      
      // Update the used count
      if (used) {
        // Mark as used
        if (newSlots[level].used < newSlots[level].max) {
          newSlots[level].used += 1;
        }
      } else {
        // Mark as available
        if (newSlots[level].used > 0) {
          newSlots[level].used -= 1;
        }
      }
      
      setSpellSlots(newSlots);
    };
    
    const handleRestClick = (restType) => {
      // Clone the current spell slots state
      const newSlots = {...spellSlots};
      
      // Reset spell slots based on rest type
      if (restType === 'long') {
        // Reset all spell slots on long rest
        Object.keys(newSlots).forEach(level => {
          newSlots[level].used = 0;
        });
      } else if (restType === 'short') {
        // For short rest, reset only certain slots based on character class
        // This would be more complex and depends on character class features
        // For now, we'll just implement a simple version
        if (characterSheet && characterSheet.system?.details?.class?.toLowerCase() === 'warlock') {
          Object.keys(newSlots).forEach(level => {
            newSlots[level].used = 0;
          });
        }
      }
      
      setSpellSlots(newSlots);
    };
    
    return (
      <div className="spell-slot-tracker">
        <div className="slot-tracker-header">
          <h3>Spell Slots</h3>
          <div className="rest-buttons">
            <button className="short-rest-btn" onClick={() => handleRestClick('short')}>
              Short Rest
            </button>
            <button className="long-rest-btn" onClick={() => handleRestClick('long')}>
              Long Rest
            </button>
          </div>
        </div>
        
        <div className="spell-slot-levels">
          {Object.keys(spellSlots).length === 0 ? (
            <div className="no-spell-slots">No spell slots available for this character.</div>
          ) : (
            Object.keys(spellSlots).map(level => (
              <div key={level} className="spell-slot-level-row">
                <div className="level-label">Level {level}</div>
                <div className="slot-counters">
                  {Array.from({ length: spellSlots[level].max }, (_, i) => (
                    <button 
                      key={i}
                      className={`slot-counter ${i < spellSlots[level].used ? 'used' : 'available'}`}
                      onClick={() => handleSlotChange(level, i, i >= spellSlots[level].used)}
                    >
                      {i < spellSlots[level].used ? '✓' : ''}
                    </button>
                  ))}
                </div>
                <div className="slot-fraction">
                  {spellSlots[level].max - spellSlots[level].used}/{spellSlots[level].max}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  // Helper function to assign colors to different classes for the ability indicators
  const getClassColor = (className) => {
    const classColors = {
      'Barbarian': '#e74c3c',
      'Bard': '#9b59b6',
      'Cleric': '#f1c40f',
      'Druid': '#27ae60',
      'Fighter': '#c0392b',
      'Monk': '#3498db',
      'Paladin': '#f39c12',
      'Ranger': '#2ecc71',
      'Rogue': '#34495e',
      'Sorcerer': '#e67e22',
      'Warlock': '#8e44ad',
      'Wizard': '#2980b9',
      'General': '#7f8c8d'
    };
    
    return classColors[className] || classColors.General;
  };

  // Function to render ability tracker
  const renderAbilityTracker = () => {
    // Group abilities by class
    const abilitiesByClass = abilityResources.reduce((acc, ability) => {
      const className = ability.class || 'General';
      if (!acc[className]) acc[className] = [];
      acc[className].push(ability);
      return acc;
    }, {});
    
    // Get classes for filter tabs
    const classes = Object.keys(abilitiesByClass);
    
    // Filter abilities based on active class
    const filteredAbilities = activeClassFilter === 'All' 
      ? abilityResources 
      : abilitiesByClass[activeClassFilter] || [];
    
    const toggleAbilityUsed = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => 
          ability.id === abilityId 
            ? { ...ability, active: !ability.active } 
            : ability
        )
      );
    };
    
    const handleUseAbility = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (ability.id === abilityId && ability.uses.value > 0) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.value - 1 
              } 
            };
          }
          return ability;
        })
      );
    };
    
    const handleRestoreAbility = (abilityId) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (ability.id === abilityId) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.max 
              } 
            };
          }
          return ability;
        })
      );
    };
    
    const restAllAbilities = (restType) => {
      setAbilityResources(abilities => 
        abilities.map(ability => {
          if (restType === 'long' || (restType === 'short' && ability.uses.per === 'short rest')) {
            return { 
              ...ability, 
              uses: { 
                ...ability.uses, 
                value: ability.uses.max 
              },
              active: false
            };
          }
          return ability;
        })
      );
    };
    
    return (
      <div className="ability-cooldown-tracker">
        <div className="ability-tracker-header">
          <h3>Class Abilities & Features</h3>
          <div className="rest-buttons">
            <button className="short-rest-btn" onClick={() => restAllAbilities('short')}>
              Short Rest
            </button>
            <button className="long-rest-btn" onClick={() => restAllAbilities('long')}>
              Long Rest
            </button>
          </div>
        </div>
        
        <div className="ability-filter-tabs">
          <button 
            className={`filter-tab ${activeClassFilter === 'All' ? 'active' : ''}`}
            onClick={() => setActiveClassFilter('All')}
          >
            All
          </button>
          {classes.map(className => (
            <button 
              key={className}
              className={`filter-tab ${activeClassFilter === className ? 'active' : ''}`}
              onClick={() => setActiveClassFilter(className)}
              style={{ borderBottom: `3px solid ${getClassColor(className)}` }}
            >
              {className}
            </button>
          ))}
        </div>
        
        <div className="ability-list">
          {filteredAbilities.length === 0 ? (
            <div className="no-abilities">No abilities available for this character.</div>
          ) : (
            filteredAbilities.map(ability => (
              <div 
                key={ability.id} 
                className={`ability-card ${ability.active ? 'active' : ''}`}
                style={{ borderLeft: `3px solid ${getClassColor(ability.class || 'General')}` }}
              >
                <div className="ability-header">
                  <h4 className="ability-name">{ability.name}</h4>
                  <div className="ability-actions">
                    {ability.uses.max > 0 && (
                      <div className="ability-uses">
                        <button 
                          className="use-btn"
                          onClick={() => handleUseAbility(ability.id)}
                          disabled={ability.uses.value <= 0}
                        >
                          Use
                        </button>
                        <span className="uses-counter">{ability.uses.value}/{ability.uses.max}</span>
                        <button 
                          className="restore-btn"
                          onClick={() => handleRestoreAbility(ability.id)}
                          disabled={ability.uses.value >= ability.uses.max}
                        >
                          Restore
                        </button>
                      </div>
                    )}
                    <button 
                      className={`toggle-active-btn ${ability.active ? 'active' : ''}`}
                      onClick={() => toggleAbilityUsed(ability.id)}
                    >
                      {ability.active ? 'Active' : 'Inactive'}
                    </button>
                  </div>
                </div>
                <div className="ability-description" dangerouslySetInnerHTML={{ __html: ability.description }} />
                {ability.uses.max > 0 && (
                  <div className="ability-footer">
                    <span className="rest-info">Recharges on: {ability.uses.per}</span>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  // Function to render favorites tab
  const renderFavorites = () => {
    if (favoriteSpells.length === 0) {
      return (
        <div className="no-favorites">
          <p>You haven't added any spells to your favorites yet.</p>
          <p>Browse the spell library and click the star icon to add spells to your favorites.</p>
        </div>
      );
    }
    
    // Sort favorites by level, then name
    const sortedFavorites = [...favoriteSpells].sort((a, b) => {
      const levelA = parseInt(a.level || a.system?.level || 0);
      const levelB = parseInt(b.level || b.system?.level || 0);
      if (levelA !== levelB) return levelA - levelB;
      return a.name.localeCompare(b.name);
    });
    
    return (
      <div className="favorites-container">
        <div className="favorites-header">
          <h3>My Favorite Spells</h3>
        </div>
        
        <div className="spell-list-container">
          <table className="spell-list-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Level</th>
                <th>School</th>
                <th>Casting Time</th>
                <th>Range</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedFavorites.map(spell => {
                const spellId = getSpellId(spell);
                const isExpanded = expandedSpells[spellId];
                
                return (
                  <React.Fragment key={spellId}>
                    <tr className="spell-row">
                      <td className="spell-name">
                        <div className="spell-name-container">
                          <button 
                            className="expand-btn"
                            onClick={() => toggleSpellExpand(spellId)}
                          >
                            {isExpanded ? '▼' : '►'}
                          </button>
                          <span>{spell.name}</span>
                        </div>
                      </td>
                      <td className="spell-level">
                        {spell.level === 0 ? 'Cantrip' : `Level ${spell.level}`}
                      </td>
                      <td className="spell-school">{spell.school}</td>
                      <td className="spell-casting-time">{formatCastingTime(spell)}</td>
                      <td className="spell-range">{formatRange(spell)}</td>
                      <td className="spell-actions">
                        <button 
                          className="favorite-btn favorite"
                          onClick={() => handleAddToFavorites(spell)}
                          title="Remove from favorites"
                        >
                          ★
                        </button>
                      </td>
                    </tr>
                    {isExpanded && (
                      <tr className="spell-details-row">
                        <td colSpan="6" className="spell-details">
                          <div className="spell-description">
                            <div className="spell-meta">
                              <div className="spell-meta-item">
                                <strong>Components:</strong> {spell.components?.v ? 'V' : ''}{spell.components?.s ? 'S' : ''}{spell.components?.m ? 'M' : ''}
                                {spell.components?.m ? ` (${spell.components.m})` : ''}
                              </div>
                              <div className="spell-meta-item">
                                <strong>Duration:</strong> {spell.duration || 'Instantaneous'}
                              </div>
                              <div className="spell-meta-item">
                                <strong>Source:</strong> {spell.source || spell.sourceBook || 'Unknown'}
                              </div>
                            </div>
                            <div 
                              className="spell-text"
                              dangerouslySetInnerHTML={{ __html: spell.entries ? spell.entries.join('<br>') : spell.system?.description?.value || 'No description available' }}
                            />
                            {spell.entriesHigherLevel && (
                              <div className="higher-levels">
                                <strong>At Higher Levels:</strong>
                                <div dangerouslySetInnerHTML={{ __html: spell.entriesHigherLevel[0].entries.join('<br>') }} />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderSpellManagerTab = () => {
    return (
      <div className="spell-manager-tab">
        <div className="spell-manager-header">
          <div className="spell-manager-title">
            <h2>
              {viewMode === 'library' && 'Spell Library'}
              {viewMode === 'slots' && 'Spell Slots'}
              {viewMode === 'abilities' && 'Class Abilities'}
              {viewMode === 'favorites' && 'My Favorites'}
            </h2>
          </div>
          <div className="view-selector">
            <button 
              className={`view-btn ${viewMode === 'library' ? 'active' : ''}`}
              onClick={() => setViewMode('library')}
            >
              Spell Library
            </button>
            <button 
              className={`view-btn ${viewMode === 'slots' ? 'active' : ''}`}
              onClick={() => setViewMode('slots')}
            >
              Spell Slots
            </button>
            <button 
              className={`view-btn ${viewMode === 'abilities' ? 'active' : ''}`}
              onClick={() => setViewMode('abilities')}
            >
              Abilities
            </button>
            <button 
              className={`view-btn ${viewMode === 'favorites' ? 'active' : ''}`}
              onClick={() => setViewMode('favorites')}
            >
              Favorites
            </button>
          </div>
          
          {viewMode === 'library' && (
            <div className="search-filters">
              <div className="search-input-container">
                <input
                  type="text"
                  className="spell-search"
                  placeholder="Search spells..."
                  value={spellSearch}
                  onChange={(e) => setSpellSearch(e.target.value)}
                />
              </div>
              <div className="filter-buttons">
                <button 
                  className={`filter-button ${activeSpellFilters.level.length > 0 ? 'active' : ''}`}
                  onClick={() => setFilterMenuOpen('level')}
                >
                  Level
                </button>
                <button 
                  className={`filter-button ${activeSpellFilters.school.length > 0 ? 'active' : ''}`}
                  onClick={() => setFilterMenuOpen('school')}
                >
                  School
                </button>
              </div>
            </div>
          )}
        </div>
        
        <div className="spell-manager-content">
          {viewMode === 'library' && renderSpellLibrary()}
          {viewMode === 'slots' && renderSpellSlotTracker()}
          {viewMode === 'abilities' && renderAbilityTracker()}
          {viewMode === 'favorites' && renderFavorites()}
        </div>
      </div>
    );
  };

  return (
    <div className="spells-and-abilities-page">
      <header className="page-header">
        <h1>Spells & Abilities</h1>
        <button className="back-to-dashboard" onClick={() => navigate('/dashboard')}>
          Back to Dashboard
        </button>
      </header>
      
      <main className="page-content">
        {renderSpellManagerTab()}
      </main>
    </div>
  );
};

export default SpellsAndAbilities; 